import React from "react"
import Content from "../components/content"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Dezvoltarea competentelor | Telecommunication Networks SRL"
      description="Pentru a promova dezvoltarea profesionala a angajatilor sai si a
        dezvolta abilitatilelor pentru proiecte noi, TC Networks implementeaza
        instruirea dedicata si promoveaza impartasirea experientei si
        transmiterea cunostintelor."
    />
    <Content>
      <h2>POLITICA DE CALITATE</h2>
      <strong>Dezvoltarea competentelor</strong>
      <p>
        Pentru a promova dezvoltarea profesionala a angajatilor si a dezvolta
        abilitatile lor pentru proiecte noi - TC Networks implementeaza
        instruirea dedicata si promoveaza impartasirea experientei si
        transmiterea cunostintelor tinand cont de trei principii:
      </p>
      <ul>
        <li>
          Respect pentru satisfactia clientilor (calitate, rentabilitatea
          proiectelor)
        </li>
        <li>Mentinerea expertizei tehnologice la un nivel ridicat</li>
        <li>
          Constientizarea mediului concurential in care se incadreaza proiectele
        </li>
      </ul>
      <p>
        TC Networks sustine principiul ca performanta colectiva este mai
        puternica decat suma abilitatilor individuale.
      </p>
      <strong>Sanatate si securitatea in munca</strong>
      <p>
        Deoarece activitatea desfasurata poate fi periculoasa (munca la
        inaltime, manipulare , riscuri electric) TC Networks desfasoara o
        politica proactiva de securitate si prevenire pentru a imbunatati
        siguranta angajatilor in centrele sale de activitate. Aceasta abordare
        se bazeaza pe analiza si evaluarea riscurilor (plan de prevenire, fise
        de siguranta), pregatirea echipelor, furnizarea de echipament de
        protectie.
      </p>
      <h2>DEZVOLTAREA DURABILA</h2>
      <p>
        Respectul pentru mediu este o dimensiune integrată în mod natural în
        organizația și serviciile noastre. Acțiunea noastră proactivă și
        continuă include următoarele angajamente:
      </p>
      <ul>
        <li>Sprijinirea parteneriatului cu intreprinderile locale</li>
        <li>
          Imbunatatirea gestionarii deseurilor noastre de pe santier, colectarea
          si predarea spre reciclare a acestora
        </li>
        <li>Controlul consumului de energie</li>
        <li>Respectarea cerintelor administrative si civile de poluare</li>
      </ul>
    </Content>
  </Layout>
)
